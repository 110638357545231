import { Injectable } from "@angular/core";
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable } from "rxjs";
import { CacheService } from "./cache.service";
import { JWTService } from "./jwt.service";

interface I_Activity {
  timestamp: number;
}

const LAST_ACTIVITY_KEY = "lastActivity";

@Injectable({
  providedIn: "root",
})
export class ActivityMonitorService {
  private _activity: BehaviorSubject<I_Activity> = new BehaviorSubject({ timestamp: Date.now() });
  private _focus: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private _cacheService: CacheService, private _jwtService: JWTService) {
    this._activity.pipe(debounceTime(1000)).subscribe((activity) => {
      const ttl = this._jwtService.getJWT().exp * 1000; // Activity should be cleared when the JWT expires

      this._cacheService.setJson(LAST_ACTIVITY_KEY, activity, ttl);
    });

    const lastActivity = this._cacheService.getJson<I_Activity>(LAST_ACTIVITY_KEY);

    if (lastActivity) {
      this._activity.next(lastActivity);
    }
  }

  public get activity(): BehaviorSubject<I_Activity> {
    return this._activity;
  }

  public get lastActivity(): number {
    return this._activity.value.timestamp;
  }

  public get focus(): Observable<boolean> {
    return this._focus.pipe(distinctUntilChanged());
  }

  public track(): void {
    this._activity.next({
      timestamp: Date.now(),
    });
  }

  public onBlur(): void {
    this._focus.next(false);
  }

  public onFocus(): void {
    this._focus.next(true);
  }

  public clear(): void {
    const activity = {
      timestamp: Date.now(),
    };

    this._activity.next(activity);
    this._cacheService.delete(LAST_ACTIVITY_KEY);
  }
}
