import { Injectable } from "@angular/core";
import { Enums } from "@apis/_core/types/enums";
import { normaliseMobilePhone } from "@shared/utils";
import { PatientEntry } from "src/app/data_model/patient";
import { VerificationService } from "./verification.service";
import { HttpService } from "./http.service";
import { lastValueFrom, Subject } from "rxjs";
import { JWTService } from "./jwt.service";
import { PatientsService } from "./patients.service";
import { FORMATS } from "@shared/constants";
import dayjs from "dayjs";
import { BookableAppointmentCategoryEntry } from "src/app/data_model/bookable-appointment";

export enum E_CheckPatientResponse {
  NEW = "NEW",
  EXISTING = "EXISTING",
  ERROR = "ERROR",
}

@Injectable({
  providedIn: "root",
})
export class NewPatientService {
  public onPatientCheck: Subject<E_CheckPatientResponse> = new Subject<E_CheckPatientResponse>();
  constructor(
    private _verificationService: VerificationService,
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _patientsService: PatientsService
  ) {}
  private _patient: PatientEntry | null = null;
  private _siteId: string;
  private _existing: boolean;

  public set patient(new_patient: PatientEntry | null) {
    this._patient = new_patient;
  }

  public get patient(): PatientEntry | null {
    return this._patient;
  }

  public set site_id(site_id: string) {
    this._siteId = site_id;
  }

  public get site_id(): string {
    return this._siteId;
  }

  public get existing(): boolean {
    return this._existing;
  }

  public get formatted_patient_mobile(): string {
    if (!this._patient) return "";
    const { mobile_phone, mobile_phone_country } = this._patient;
    return normaliseMobilePhone(mobile_phone, mobile_phone_country, false);
  }

  public async sendVerificationCode() {
    if (!this._patient) return;
    await this._verificationService.sendNewPatientVerificationCode(Enums.Google_Recaptcha_Action.NEW_PATIENT, this._siteId, this._patient);
  }

  public async verifyCode(code: string) {
    await this._verificationService.verifyCode(Enums.Google_Recaptcha_Action.NEW_PATIENT, code);
  }

  public checkPatientExists(): void {
    if (!this._patient) return;

    const query = `{
      checkPatientExists(patient_to_check: {
        title: "${this._patient.title}"
        first_name: "${this._patient.first_name}"
        last_name: "${this._patient.last_name}"
        date_of_birth: "${this._patient.date_of_birth}"
        mobile_phone: "${this._patient.mobile_phone}"
        mobile_phone_country: "${this._patient.mobile_phone_country}"
        ${this._generateAdditionalFields(this._patient)}
      }) {
        id
      }
    }`;

    this._httpService.mutation("checkPatientExists", query).subscribe((response) => {
      if (response.errors) {
        this.onPatientCheck.next(E_CheckPatientResponse.ERROR);
        return;
      }
      const { checkPatientExists } = response.data;
      if (checkPatientExists?.id) {
        this.onPatientCheck.next(E_CheckPatientResponse.EXISTING);
      } else {
        this.onPatientCheck.next(E_CheckPatientResponse.NEW);
      }
    });
  }

  public async createPatient(
    siteId: string,
    paymentPlanId: string | null,
    appointmentCategory?: BookableAppointmentCategoryEntry | undefined,
    practitionerId?: string
  ): Promise<void> {
    if (!this._patient) return;

    const practitionerType = this._getPractitionerType(appointmentCategory);

    const response = await lastValueFrom(
      this._httpService.mutation(
        "createPatient",
        `{
      createPatient(new_item: {
        site_id: "${siteId}"
        title: "${this._patient.title}"
        first_name: "${this._patient.first_name}"
        last_name: "${this._patient.last_name}"
        date_of_birth: "${this._patient.date_of_birth}"
        mobile_phone: "${this._patient.mobile_phone}"
        mobile_phone_country: "${this._patient.mobile_phone_country}"
        ${this._generateAdditionalFields(this._patient)}
        gender: "${this._patient.gender || "F"}"
        ${paymentPlanId ? `payment_plan_id: "${paymentPlanId}"` : ""}
        ${practitionerType ? `practitioner_type: ${practitionerType}` : ""}
        ${practitionerId ? `practitioner_id: "${practitionerId}"` : ""}
      }) {
        id
        jwt
      }
    }`
      )
    );

    const { id, existing, jwt } = response.data.createPatient;

    this._patient.id = id;
    this._patient.site_id = siteId;
    this._existing = existing;
    this._jwtService.setToken(jwt);
    this._patientsService.patientInfo = this._patient;
    this._patientsService.patientInfo.date_of_birth = dayjs(this._patient.date_of_birth, FORMATS.LOCALE_DATE).format("YYYY-MM-DD");
  }

  private _generateAdditionalFields(patient: PatientEntry): string {
    return `
      email_address: "${patient.email_address}"
      address_line_1: "${patient.address_line_1}"
      postcode: "${patient.postcode}"
    `;
  }

  private _getPractitionerType(appointmentCategory: BookableAppointmentCategoryEntry | undefined): "dentist" | "hygienist" | null {
    if (!appointmentCategory) return null;

    if (appointmentCategory?.dental_appointment) {
      return "dentist";
    }

    if (appointmentCategory?.hygiene_appointment) {
      return "hygienist";
    }

    return null;
  }
}
