import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from "@angular/core";
import { environment } from "./environments/environment";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { APP_ROUTES, LoginAuthGuard, PatientTypeSelectionAuthGuard, RestrictedAuthGuard } from "./app/app.routing";
import { AppInitService } from "./app/shared/services/app-init.service";
import { HttpErrorHandler } from "./app/shared/services/http-error-handler.service";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AppComponent } from "./app/app.component";
import { HttpInterceptorProviders } from "./app/shared/http-interceptors";
import { CurrencyPipe } from "@angular/common";
import { JWTService } from "./app/shared/services/jwt.service";
import { BookableAppointmentsAuthGuard } from "./app/bookable-appointments/bookable-appointments.routing";
import { provideServiceWorker } from "@angular/service-worker";
import { GenericSessionService, PipSessionService, SessionService } from "./app/shared/services/session.service";
import { HttpService } from "./app/shared/services/http.service";
import { CacheService } from "./app/shared/services/cache.service";
import { GAService } from "./app/shared/services/ga.service";
import { WindowService } from "./app/shared/services/window.service";
import { LocationService } from "./app/shared/services/location.service";
import { ReEnterPasswordGuard } from "./app/login-v4/login-v4.routing";
import { ActivityMonitorService } from "./app/shared/services/activity-monitor.service";
import { NavigationService } from "./app/shared/services/navigation.service";

if (environment.production) {
  enableProdMode();
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    AppInitService,
    JWTService,
    PatientTypeSelectionAuthGuard,
    RestrictedAuthGuard,
    LoginAuthGuard,
    ReEnterPasswordGuard,
    BookableAppointmentsAuthGuard,
    HttpInterceptorProviders,
    HttpErrorHandler,
    CurrencyPipe,
    { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [AppInitService] },
    {
      provide: APP_INITIALIZER, // see https://angular.io/api/core/APP_INITIALIZER
      useFactory: (appInitService: AppInitService) => (): Promise<any> => appInitService.init(),
      deps: [AppInitService],
      multi: true,
    },
    importProvidersFrom(BrowserModule, BrowserAnimationsModule, HttpClientModule, AngularSvgIconModule.forRoot()),
    provideServiceWorker("ngsw-worker.js", {
      enabled: environment.ENABLE_SERVICE_WORKER === "true", // Only enable service workers when ENABLE_SERVICE_WORKER is true so we can test on sandbox prior to release to production
      registrationStrategy: "registerWhenStable:30000",
    }),
    {
      provide: SessionService,
      useFactory: (
        http: HttpService,
        jwt: JWTService,
        cache: CacheService,
        ga: GAService,
        window: WindowService,
        location: LocationService,
        activityMonitor: ActivityMonitorService,
        navigationService: NavigationService
      ) => {
        if (location.isPairDomain) {
          return new PipSessionService(http, jwt, cache, ga, window, activityMonitor, navigationService);
        }

        return new GenericSessionService(http, jwt, cache, ga, window, activityMonitor, navigationService);
      },
      deps: [HttpService, JWTService, CacheService, GAService, WindowService, LocationService, ActivityMonitorService, NavigationService],
      multi: false,
    },
  ],
}).catch((err) => console.error(err));
