import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, RendererFactory2 } from "@angular/core";
import { Subject, filter, firstValueFrom, lastValueFrom, switchMap, timer } from "rxjs";
import { environment } from "src/environments/environment";
import { JWTService } from "./jwt.service";
import { PushUpdatesService } from "./push-updates.service";
import { E_MessageKind } from "@backend/graph/_services/e-message-kind";
import {
  E_SecureCrossDomainDataSharingType,
  finaliseSecureCrossDomainDataSharing,
  startSecureCrossDomainDataSharing,
} from "../utils/secure-cross-domain-data-sharing";
import { CommonService } from "./common.service";
import { NavigationService } from "./navigation.service";
import { CacheService } from "./cache.service";
import { WindowService } from "./window.service";
import { SubSink } from "subsink";
import { HttpService } from "./http.service";
import { debugAndLeaveBreadcrumb } from "../utils/logging";
import { LocationService } from "./location.service";
import { BrandService } from "./brand.service";
import { ReferrerService } from "./referrer.service";
import { E_Referrer } from "@backend/common/enums/referrer";
import Bugsnag from "@bugsnag/js";
import { PatientsService } from "./patients.service";
import { E_GlobalDomains, E_PipServiceMode } from "@shared/constants";
import { I_HeartbeatResponse } from "@backend/graph/devices/devices-base";
import { Constants } from "src/constants";
import { SessionService } from "./session.service";
import { setThemeColor, pipThemeColor } from "../utils/theme";
import { RumService } from "./rum.service";
import { I_PipData, PortalInPracticeDataService } from "./portal-in-practice-data.service";

@Injectable({
  providedIn: "root",
})
export class PortalInPracticeService implements OnDestroy {
  private _activationCode: string;
  private _practiceName: string;
  private _siteId: string;
  private _siteName: string;
  private _siteUrl: string;
  private _subs = new SubSink();
  private _deviceLocked = false;
  public onPaired: Subject<void> = new Subject<void>();
  public hadPairingError = false;

  constructor(
    private _http: HttpClient,
    private _jwtService: JWTService,
    private _pushUpdatesService: PushUpdatesService,
    private _rendererFactory: RendererFactory2,
    private _commonService: CommonService,
    private _navigationService: NavigationService,
    private _cacheService: CacheService,
    private _windowService: WindowService,
    private _httpService: HttpService,
    private _locationService: LocationService,
    private _brandService: BrandService,
    private _referrerService: ReferrerService,
    private _patientsService: PatientsService,
    private _sessionService: SessionService,
    private _rumService: RumService,
    private _portalInPracticeDataService: PortalInPracticeDataService
  ) {}

  public get activationCode(): string {
    return this._activationCode;
  }

  public get practiceName(): string {
    return this._practiceName;
  }

  public get siteId(): string {
    return this._siteId;
  }

  public get siteName(): string {
    return this._siteName;
  }

  public get siteUrl(): string {
    return this._siteUrl;
  }

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public get deviceId(): string | null {
    return this._jwtService.getJwtField("device_id");
  }

  /**
   * Generate a public JWT so that the Pusher connection can be authenticated
   */
  public async initForPairing(): Promise<void> {
    const response = (await lastValueFrom(this._http.get(`${environment.GLOBAL_URL}/v1/pair/init`))) as { token: string; activationCode: string };
    const { token, activationCode } = response;

    this._jwtService.setPublicToken(token, true);
    this._activationCode = activationCode;
    this._connectToPusher();
    setThemeColor(pipThemeColor);
  }

  public initForPaired(): void {
    this._referrerService.referrer = E_Referrer.PORTAL_IN_PRACTICE;
    this._connectToPusher();
    this._setupHeartbeat();
  }

  private _onUnlink(): void {
    Bugsnag.notify("Device unlinked");
    this._portalInPracticeDataService.deletePipData();
    this._cacheService.delete(Constants.RESTRICTED_SITE_STORAGE_KEY);
    this._cacheService.delete(Constants.BRAND_INFO_STORAGE_KEY);
  }

  private _connectToPusher(): void {
    this._subs.sink = this._pushUpdatesService.onConnected
      .pipe(
        switchMap(() => {
          return this._pushUpdatesService
            .createPrivateChannelObservable(this._jwtService.getJWT("device_id"))
            .pipe(
              filter((data) =>
                [E_MessageKind.Device_Linked, E_MessageKind.Device_Unlinked, E_MessageKind.Device_Locked, E_MessageKind.Device_Unlocked].includes(
                  data.message_kind
                )
              )
            );
        })
      )
      .subscribe(async (data) => {
        switch (data.message_kind) {
          case E_MessageKind.Device_Linked:
            const { jwt } = data.message_content as I_PipData;

            this._jwtService.setToken(jwt);
            this._processPipData(data.message_content);

            this._portalInPracticeDataService.setPipData(data.message_content);

            this._rumService.setup();

            break;

          case E_MessageKind.Device_Unlinked:
            this._onUnlink();
            await this._sessionService.clear();
            this._windowService.open(this.pairDomain);

            break;

          case E_MessageKind.Device_Locked:
            this._navigationService.navigate("/pip-login/locked");

            break;

          case E_MessageKind.Device_Unlocked:
            this.deviceLocked = false;
            this._navigationService.navigate("/pip-login");

            break;
        }
      });
  }

  public async restorePipData(): Promise<boolean> {
    const data = this._portalInPracticeDataService.getPipData();

    if (data) {
      this._processPipData(data);

      // Make sure we get the patient before restoring the PiP data to ensure the brand is set correctly
      if (this._jwtService.isPatient()) {
        // The patient must of refreshed the page while completing their forms so we need to load their data again
        // to prevent in infinite loop caused by the call to reload() in AppComponent when there is a patient JWT
        // but no patient info
        await this._patientsService.getPatient();
      }

      await this._loadBrandData();

      return true;
    }

    return false;
  }

  public async logoutPatientInPractice(): Promise<void> {
    await this._sessionService.clear();
    this._jwtService.restorePublicInPracticeToken();
  }

  private _processPipData(data: I_PipData): void {
    const { practiceName, siteId, siteName, siteUrl, stage } = data;
    const url = new URL(siteUrl);

    if (!practiceName || !siteId || !siteName || !siteUrl || !stage) {
      Bugsnag.leaveBreadcrumb("Invalid PIP data", { data });
      Bugsnag.notify(new Error("Invalid PIP data"));
    }

    this._locationService.hostname = url.hostname;

    window.STAGE = stage;
    this._practiceName = practiceName;
    this._siteId = siteId;
    this._siteName = siteName;
    this._siteUrl = siteUrl;
    this.onPaired.next();
  }

  public get pairDomain(): string {
    const isProd = environment.IS_PROD;

    if (!isProd) return `https://pair.${E_GlobalDomains.SANDBOX}`;
    return `https://pair.${E_GlobalDomains.PRODUCTION}`;
  }

  public get serviceMode(): E_PipServiceMode {
    return E_PipServiceMode.CONCIERGE;
  }

  public async finalisePairing(): Promise<void> {
    const renderer = this._rendererFactory.createRenderer(null, null);
    let url = this.pairDomain;

    if (window.localStorage.getItem("e2e")) {
      // we use the stage specific url for e2e tests
      url = document.referrer;
    } else {
      //document.referrer should always match url (except for e2es).
      //If it doesn't, it doesn't actually matter now because we are hardcoding the url, but is interesting as it might explain why the pairing sometimes failed.
      if (document.referrer !== url) console.error("document referrer does not match url", { referrer: document.referrer, url });
    }

    debugAndLeaveBreadcrumb("finalisePairing", { referrer: document.referrer, default_url: `${this.pairDomain}`, url });

    const data = await finaliseSecureCrossDomainDataSharing<{ jwt: string }>(renderer, "pip-pairing", url.replace(/\/$/, ""));
    if (!data) {
      this.hadPairingError = true;
      return;
    }

    const { jwt } = data;
    this._jwtService.setToken(jwt);
    this._commonService.getCommonData(true);
    this._commonService.onInitData.subscribe(() => {
      this._navigationService.navigate("/pip-login");
    });
    this.initForPaired();
  }

  public redirectToSiteUrl(): void {
    startSecureCrossDomainDataSharing(
      this._rendererFactory.createRenderer(null, null),
      {
        targetUrl: `${this._siteUrl}/pair`,
        messageId: "pip-pairing",
        type: E_SecureCrossDomainDataSharingType.PIP_PAIRING,
      },
      {
        jwt: this._jwtService.getJWTString(),
      }
    );
  }

  public set deviceLocked(value: boolean) {
    this._deviceLocked = value;
  }

  public get deviceLocked(): boolean {
    return this._deviceLocked;
  }

  public async continueToPipLogin(): Promise<void> {
    await this._loadBrandData();

    this._navigationService.navigate("/pip-login");
  }

  private async _waitForCommonData() {
    const promise = firstValueFrom(this._commonService.onInitData);
    this._commonService.getCommonData(true);

    await promise;
  }

  private async _loadBrandData(): Promise<void> {
    await this._commonService.getDomainInfo();
    await this._waitForCommonData();

    this._brandService.restrictedSiteId = this._siteId;
  }

  private _setupHeartbeat(): void {
    this._subs.sink = timer(0, 600000) // Immediately, then every 10 minutes
      .pipe(
        switchMap(() => {
          return this._httpService.mutation(
            "deviceHeartbeat",
            `{
              deviceHeartbeat(id: "${this._jwtService.getJWT("device_id")}") {
                success
                locked
              }
          }`
          );
        })
      )
      .subscribe((response: { data: { deviceHeartbeat: I_HeartbeatResponse } }) => {
        if (response.data.deviceHeartbeat.locked) {
          this.deviceLocked = true;
        }
      });
  }
}
