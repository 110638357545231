<main *ngIf="!loading" class="relative h-full" [ngClass]="{ 'mt-3': appView || isPatientInPractice }">
  <div class="flex h-full flex-col">
    <dentr-user-menu *ngIf="!hideUserMenu && !hideTopNav" @fadeInAnimation></dentr-user-menu>
    <div *ngIf="canUnauthenticatedLogout" class="mt-3 flex items-center justify-end">
      <span tabindex="0" role="button" (click)="signout()" (keydown.enter)="signout()" class="flex items-center">
        <span class="flex items-center text-[1.25rem]">
          <ng-icon name="heroArrowLeftOnRectangle" class="text-gray-700"></ng-icon>
        </span>
        <a class="ml-2 text-sm font-normal" data-unit-test-logout>Log out</a>
      </span>
    </div>
    <div class="mx-auto mb-10 w-full max-w-2xl flex-1" #mainContentWrapper>
      <div [@routeAnimations]="prepareRoute(outlet)" class="relative h-full w-full">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>

    <div class="mx-auto w-[calc(100vw-2rem)] shrink-0" @fadeInAnimation *ngIf="showFooter">
      <dentr-footer></dentr-footer>
    </div>
  </div>
</main>
