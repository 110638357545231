import { Injectable } from "@angular/core";
import { Observable, Subscription, debounceTime, interval, lastValueFrom, map, of, skipWhile, switchMap, throwError } from "rxjs";
import { HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import Bugsnag from "@bugsnag/js";
import { CacheService } from "./cache.service";
import { GAService } from "./ga.service";
import { Constants } from "src/constants";
import { WindowService } from "./window.service";
import { ActivityMonitorService } from "./activity-monitor.service";
import { NavigationService } from "./navigation.service";
import { environment } from "src/environments/environment";

const TESTING_STAGE = environment.STAGE === "philw";
const KEEP_ALIVE_INTERVAL = 1000 * (TESTING_STAGE ? 5 : 60 * 5); // 5 minutes
const INACTIVITY_TIMEOUT = 1000 * (TESTING_STAGE ? 60 : 60 * 60 * 2); // 2 hours

@Injectable({
  providedIn: "root",
})
export abstract class SessionService {
  constructor(
    protected _httpService: HttpService,
    protected _jwtService: JWTService,
    protected _cacheService: CacheService,
    protected _gaService: GAService,
    protected _windowService: WindowService,
    protected _activityMonitorService: ActivityMonitorService,
    protected _navigationService: NavigationService
  ) {
    this._jwtService.onSessionIdChanged.subscribe(() => {
      this._setSessionId();
    });
  }

  public get isPasswordRequired(): boolean {
    return false;
  }

  public abstract init(): Promise<void>;

  public async clear(): Promise<void> {
    try {
      await lastValueFrom(
        this._httpService.send(`/sessions`, {
          method: "DELETE",
        })
      );
    } catch (error) {
      console.error("error clearing session", error);
      Bugsnag.notify(error);
    }
    this._jwtService.delete();
  }

  public onPageUnload(): void {}

  public async logout(): Promise<void> {
    this._cacheService.deleteSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY);
    Bugsnag.leaveBreadcrumb("Sign out");
    this._gaService.action("signout");
    this._cacheService.clearSession();
    await this.clear();
    this._windowService.href = "/signout";
  }

  public startKeepAlive(): void {}

  protected _setSessionId(): void {}
}

export class GenericSessionService extends SessionService {
  private _intervalSubscription: Subscription;
  private _activitySubscription: Subscription;
  private _focusSubscription: Subscription;
  private _invalidJWTSubscription: Subscription;
  private _lastActivity: number;

  public get isPasswordRequired(): boolean {
    return this._shouldEnable && this._isPasswordRequired(this._lastActivity);
  }

  public async init(): Promise<void> {
    this._lastActivity = this._activityMonitorService.lastActivity;

    try {
      const jwt = await lastValueFrom(this._getSession());

      if (!jwt) return;

      const existingJWT = this._jwtService.getJWTString();

      if (!!existingJWT) {
        if (existingJWT !== jwt) Bugsnag.notify(new Error("JWT stored in local storage does not match the one returned from the server"));

        return;
      }

      // TODO: once we're happy that this is working (i.e. no Bugsnag errors are being reported) we can set the token in memory and
      //       prevent it being stored the local storage
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  private _getSession(): Observable<string | null> {
    return this._httpService
      .send(`/sessions`, {
        method: "GET",
      })
      .pipe(map((response: { jwt: string } | null) => response?.jwt || null));
  }

  private _setSession(): Observable<string | any[] | null> {
    return this._httpService.send(`/sessions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this._jwtService.getJWTString()}`,
      },
    });
  }

  protected _setSessionId(): void {
    try {
      this._setSession().subscribe();
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  public clear(): Promise<void> {
    this._activityMonitorService.clear();

    return super.clear();
  }

  public startKeepAlive(): void {
    if (!this._shouldEnable) return;

    if (!this._jwtService.isLoggedIn()) {
      // We only want to keep the session alive for logged in patients because other mechanisms are in place for other access levels
      return;
    }

    this._clearActivitySubscriptions();

    if (this._isPasswordRequired(this._lastActivity)) {
      this._navigateToReEnterPassword();

      return;
    }

    this._intervalSubscription = interval(KEEP_ALIVE_INTERVAL).subscribe(() => {
      if (Date.now() - this._lastActivity > KEEP_ALIVE_INTERVAL) {
        // No activity since last keep alive
        return;
      }

      this._handleActivity();
    });

    this._activitySubscription = this._activityMonitorService.activity.pipe(debounceTime(1000)).subscribe((activity) => {
      if (this._isPasswordRequired(this._lastActivity)) {
        this._navigateToReEnterPassword();

        return;
      }

      this._lastActivity = activity.timestamp;
    });

    this._focusSubscription = this._activityMonitorService.focus
      .pipe(skipWhile((focus) => focus)) // Ignore the default value
      .subscribe(() => {
        if (this._isPasswordRequired(this._lastActivity)) {
          this._navigateToReEnterPassword();
        } else {
          this._lastActivity = Date.now();
        }

        this._handleActivity();
      });

    this._invalidJWTSubscription = this._jwtService.onInvalidJWT.subscribe(() => {
      this._activityMonitorService.clear();
    });
  }

  private _clearActivitySubscriptions(): void {
    if (this._intervalSubscription) this._intervalSubscription.unsubscribe();
    if (this._activitySubscription) this._activitySubscription.unsubscribe();
    if (this._focusSubscription) this._focusSubscription.unsubscribe();
    if (this._invalidJWTSubscription) this._invalidJWTSubscription.unsubscribe();
  }

  private _handleActivity(): void {
    if (this._isPasswordRequired(this._lastActivity)) {
      this._navigateToReEnterPassword();

      return;
    }

    this._lastActivity = Date.now();

    this._extendSession();
  }

  private _extendSession(): void {
    this._httpService
      .mutation("extendSession", `{ extendSession(last_activity:${(this._lastActivity / 1000).toFixed(0)}) }`)
      .pipe(
        switchMap((response) => {
          if (response.data?.errors?.length) {
            return throwError(() => new Error(response.errors[0].message));
          }

          return of(response);
        })
      )
      .subscribe({
        error: () => {
          this._navigateToReEnterPassword();
        },
      });
  }

  private _isPasswordRequired(lastActivity: number): boolean {
    return Date.now() - lastActivity > INACTIVITY_TIMEOUT;
  }

  private _navigateToReEnterPassword(): void {
    this._clearActivitySubscriptions();

    this._navigationService.navigate("/login/re-enter-password");
  }

  private get _shouldEnable(): boolean {
    return ["testing", "philw"].includes(environment.STAGE);
  }
}

const TOKEN_STORAGE_KEY = "token";

export class PipSessionService extends SessionService {
  public init(): Promise<void> {
    const token = this._cacheService.getSession(TOKEN_STORAGE_KEY);

    if (token) {
      this._jwtService.setToken(token);
      this._cacheService.deleteSession(TOKEN_STORAGE_KEY);
    }

    return Promise.resolve();
  }

  public onPageUnload(): void {
    this._cacheService.setSession(TOKEN_STORAGE_KEY, this._jwtService.getJWTString());
  }
}
